
export default {
  props: {
    headerColor: {
      type: String,
      required: false,
      default: null
    },
    showHeaders: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.loadingCount > 0
    },
    contentClasses () {
      const cls = ['!tw-pt-0']

      if (this.showHeaders) {
        cls.push('tw-relative', this.hasTopHeader() ? '!tw-top-28' : '!tw-top-14')
      }

      return cls
    },
    sidebarClasses () {
      const cls = []

      if (!this.showHeaders) {
        cls.push('!tw-top-0')
      }

      return cls
    },
    previewClasses () {
      const cls = []

      if (!this.showHeaders) {
        cls.push('!tw-top-0')
      }

      return cls
    }
  },
  methods: {
    hasTopHeader () {
      return !!this.$slots['top-header']
    },
    hasSidebar () {
      return !!this.$slots.sidebar
    },
    hasOverlay () {
      return !!this.$slots['content-overlay']
    },
  }
}
