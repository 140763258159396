import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { PARTNER_TYPE_FINAGO } from '~/utils/constants';
import { appendQuery } from '~/utils/url';
var fallbackUrls = {
  privacy_policy: 'https://docue.com/en/privacy',
  terms: 'https://docue.com/en/terms'
};
var partnerUrls = _defineProperty({}, PARTNER_TYPE_FINAGO, {
  privacyPolicy: 'https://finago.com/fi/tietosuojaseloste/',
  terms: 'https://finago.com/fi/finago-sopimuskone-kayttoehdot/'
});
export default function (ctx, inject) {
  // uses api v1 camelcase format for keys
  var linkGetter = function linkGetter(_ref) {
    var key = _ref.key,
      _ref$partnerKey = _ref.partnerKey,
      partnerKey = _ref$partnerKey === void 0 ? key : _ref$partnerKey;
    return function () {
      var _account$partner$type, _account$partner, _ctx$store$state$part, _ctx$store$state$part2, _partnerUrls$partnerT, _ctx$store$getters$au;
      var account = ctx.store.getters['auth/account'];
      var partnerType = (_account$partner$type = account === null || account === void 0 ? void 0 : (_account$partner = account.partner) === null || _account$partner === void 0 ? void 0 : _account$partner.type) !== null && _account$partner$type !== void 0 ? _account$partner$type : (_ctx$store$state$part = ctx.store.state.partner.partner) === null || _ctx$store$state$part === void 0 ? void 0 : (_ctx$store$state$part2 = _ctx$store$state$part.attributes) === null || _ctx$store$state$part2 === void 0 ? void 0 : _ctx$store$state$part2.type;
      if (account !== null && account !== void 0 && account.partner && account.partner["".concat(partnerKey, "Url")]) {
        return account.partner["".concat(partnerKey, "Url")];
      } else if (partnerType && partnerUrls !== null && partnerUrls !== void 0 && (_partnerUrls$partnerT = partnerUrls[partnerType]) !== null && _partnerUrls$partnerT !== void 0 && _partnerUrls$partnerT[partnerKey]) {
        return partnerUrls[partnerType][partnerKey];
      }
      var configKey = "link_".concat(key);
      return ((_ctx$store$getters$au = ctx.store.getters['auth/config']) === null || _ctx$store$getters$au === void 0 ? void 0 : _ctx$store$getters$au[configKey]) || fallbackUrls[key];
    };
  };
  inject('links', {
    privacyPolicy: linkGetter({
      key: 'privacy_policy',
      partnerKey: 'privacyPolicy'
    }),
    terms: linkGetter({
      key: 'terms'
    }),
    pricing: linkGetter({
      key: 'pricing'
    }),
    supportPortal: linkGetter({
      key: 'support_portal',
      partnerKey: 'supportPortal'
    }),
    subscriptionUpkeep: function subscriptionUpkeep() {
      return '/2/subscription/upkeep';
    },
    cancelSubscription: function cancelSubscription() {
      return '/2/cancellation';
    },
    item: {
      builder: function builder(itemId) {
        return "/builder/edit?itemId=".concat(itemId);
      },
      archive: function archive(itemId) {
        return "/documents?accountItemId=".concat(itemId);
      }
    },
    document: {
      archive: function archive(documentId) {
        return "/2/documents/d/".concat(documentId);
      }
    },
    contract: {
      builder: function builder(contractId) {
        return "/builder/edit?cId=".concat(contractId);
      }
    },
    templates: {
      root: function root() {
        return '/2/templates';
      },
      recent: function recent() {
        return '/2/templates/recent';
      },
      favourites: function favourites() {
        return '/2/templates/favourites';
      },
      own: function own() {
        return '/2/templates/own';
      },
      all: function all() {
        return '/2/templates/all';
      },
      single: function single(id) {
        var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        return appendQuery("/2/template/".concat(id), query);
      },
      singleOwn: function singleOwn(id) {
        var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        return appendQuery("/2/template/own/".concat(id), query);
      },
      category: function category(categoryId) {
        return "/2/templates/category/".concat(categoryId);
      },
      collection: function collection(collectionId) {
        return "/2/templates/collection/".concat(collectionId);
      },
      search: function search() {
        return '/2/templates/all';
      }
    },
    subscribe: function subscribe() {
      return '/subscribe';
    },
    website: {
      UKPricingPage: function UKPricingPage() {
        return 'https://docue.com/en-gb/pricing';
      }
    }
  });
}