import { removeActiveAccount, removeApiToken } from '~/utils/cookie'
export default function axiosAuthInterceptor (ctx) {
  const { $axios, redirect } = ctx

  $axios.onError((err) => {
    // To prevent race-condiditions when intentionally trying to fetch
    // user profile without valid credentials (to check if they are valid)
    // we do not redirect on GET /me profile fetches.
    if (err?.request?.responseURL) {
      const url = new URL(err?.request?.responseURL)
      if (['/api/me', '/me'].includes(url.pathname)) {
        return
      }
    }

    if (err?.response?.status === 401 || (err?.response?.status === 403 && err?.response?.data.error_type === 'auth_restrictions_not_met')) {
      removeApiToken()
      removeActiveAccount()

      if (location.pathname !== '/login') {
        const currentUrl = new URL(location.href)
        const isChildApp = currentUrl.searchParams.has('is-child-app')

        if (isChildApp) {
          redirect('/login')
        } else {
          redirect(`/login?return=${encodeURIComponent(location.href)}`)
        }
      }
    }
  })
}
